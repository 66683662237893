export default {
  validation: {
    email: {
      valid: 'Must be a valid email',
      req: 'Email is required',
      confirm: 'Email has changed. Please confirm.',
      help: 'We will use this email to contact you',
      verification: 'Verification code sended to',
      changed: 'Email changed! Verification code sended to',
      resended: 'New verification code sended to',
      verified: 'Email sucessfully verified!',
      wrongCode: 'Verification code is wrong or expired.',
    },
    password: {
      valid: 'Must be a valid password',
      req: 'Password is required',
      lower: 'Must contain at least 1 lowercase letter',
      upper: 'Must contain at least 1 uppercase letter',
      number: 'Must contain at least 1 number',
      length: 'Must be at least 8 characters',
    },
    phoneNumber: {
      valid: 'Phone number is not valid',
    },
    confirm: {
      valid: 'Is not the same',
      req: 'Confirmation is required',
    },
    policy: {
      req: 'This field must be checked',
    },
    code: {
      req: 'Code is required',
    },
    name: {
      req: 'Name is required',
      notOnlyWhiteSpaceReq: 'Name should be at least 1 no white space characters long',
    },
    type: {
      req: 'Type is required',
    },
    field: {
      number: 'Must be a number',
      req: 'This field is required',
      integer: 'Must be an integer',
      positiveNumber: 'Must be greater or equal to 0',
    },
    location: {
      req: 'Location is required',
      beatBoxNumber: 'Beat Box number must be uniq',
    },
    date: {
      req: 'Correct date is required',
    },
    survey: {
      req: 'Please fill out all or none of survey fields',
      wrongUrl: 'Please use valid link format',
      invalidDate: 'Please use valid date',
    },
    game: {
      thisGameCannotBeRemoved: 'This game cannot be removed',
    },
    id: {
      req: 'Please provide ID for this field.',
    },
    title: {
      notOnlyWhiteSpaceReq: 'Title should be at least 1 no white space characters long',
    },
  },
  time: {
    updated: 'Updated',
    ago: 'ago',
  },
  navigation: {
    stats: 'Game Stats',
    general: 'General',
    setup: 'Setup',
    edit: 'Edit',
    dashboard: 'Dashboard',
    locations: 'Locations',
    games: 'Games',
    location: 'Location',
    reportedIssues: 'Reported Issues',
    events: 'Events',
    account: 'Account',
    members: 'Members',
    settings: 'Settings',
    logout: 'Logout',
    profile: 'Profile',
    players: 'Players',
    teams: 'Teams',
    cards: 'Cards',
    leaderboards: 'Leaderboards',
    cardOrders: 'Card Orders',
    invoices: 'Invoices',
    mapping: 'Mapping',
    prizes: 'Prizes',
    beatBoxes: 'Beat Boxes',
    workflows: 'Workflows',
    sales: 'Sales',
    opportunities: 'Opportunities',
    pipeline: 'Pipeline',
    masterBudget: 'Master Budget',
    time: 'Time',
    rules: 'Rules',
    timelines: 'Timelines',
    details: 'Details',
    proposal: 'Proposal',
    stakeholder: 'Stakeholder',
    view: 'View',
    editMap: 'Edit map',
    tagRanges: 'Game ranges',
    tagSubranges: 'Team ranges',
    surveys: 'Surveys',
    eventPoints: 'Event Points',
    overview: 'Overview',
    analytics: 'Analytics',
    demographics: 'Demographics',
    activity: 'Activity',
    engagement: 'Engagement',
    security: 'Security',
    segments: 'Segments',
    messages: 'Messages',
    schedule: 'Schedule',
    delivery: 'Delivery',
    templates: 'Templates',
  },
  errors: {
    invalidCredentials: 'Incorrect username or password.',
    passEqual: 'Passwords must match.',
    logout: 'Unable to logout',
    401: '401: Authorization required',
    404: 'Page not found',
    500: '500: Internal Server Error',
    codeSub: "Sorry we couldn't find what you're looking for...",
    generic: 'Something went wrong, try again later',
  },
  labels: {
    password: 'Password',
    newPass: 'New password',
    oldPass: 'Old password',
    passConfirm: 'Confirm password',
    code: 'Verification code',
    name: 'Name',
    slug: 'Slug',
    accessGames: 'Access all games',
    manageGames: 'Manage games',
    type: 'Type',
    description: 'Description',
    points: 'Points',
    members: 'Members',
    range: 'Range',
    pendingApproval: 'Range',
    all: 'All',
    date: 'Date',
    using: 'Using',
    selectOption: 'Select option',
    id: 'ID',
    link: 'Link',
    tellUsAboutYou: 'Health survey',
    closing: 'Closing survey',
    sixMonths: 'First follow up',
    oneYear: 'Second follow up',
    shownFrom: 'Shown from',
    status: 'Status',
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    reasonForRejection: 'Reason for rejection',
    sendDate: 'Send date',
    target: 'Participation target',
    city: 'City',
    lat: 'Latitude',
    lon: 'Longitude',
    email: 'Email',
    admin: 'Admin',
    partner: 'Partner',
    installer: 'Installer',
    restricted: 'Restricted',
    restrictAccess: 'Restrict access',
    locationDistanceMeters: 'Location distance (meters)',
  },
  members: {
    name: 'Members',
    single: 'Member',
    add: 'Add member',
    add2: 'Add new member',
    list: 'Members List',
    type: 'Member Type',
    lastActivity: 'Last Activity',
    isAdmin: 'Admin access',
    isAdminSub: 'Allow to manage all games',
    playerData: 'Player data',
    playerDataSub: 'Can export player details',
    manageMapping: 'Manage mapping',
    manageMappingSub: 'Can access and manage mapping workflows',
    isPartner: 'Partner access',
    isPartnerSub: 'Can manage the games they are assigned to',
    deleteMember: 'Delete Member',
    deleteMemberWarning: 'Please be aware that once deleted the Member cannot be restored',
    searchMembers: 'Search members',
    existingPlayer: 'Already existing Player',
    access: 'Access',
    memberDetails: {
      account: 'Account',
    },
  },
  common: {
    req: 'Required',
    cancel: 'Cancel',
    add: 'Add',
    confirm: 'Confirm',
    back: 'Back',
    backHome: 'Back to Home',
    saveChanges: 'Save Changes',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    all: 'All',
    email: 'Email',
    mobile: 'Mobile',
    username: 'Username',
    country: 'Country',
    age: 'Age',
    gender: 'Gender',
    postCode: 'Post Code',
    actions: 'Actions',
    create: 'Create',
    yes: 'Yes',
    no: 'No',
    submit: 'Submit',
    importFromCSV: 'Import from CSV',
    ok: 'Ok',
    choose: 'Choose',
    search: 'Search',
    duplicate: 'Duplicate',
    close: 'Close',
    remove: 'Remove',
  },
  auth: {
    login: 'Log In',
    changePass: 'Change Password',
    verify: 'Verify',
    signin: 'Sign in',
    signinSub: 'Sign in on the internal platform',
    newPass: 'Set new password',
    newPassSub: ' Set the new password and start using the platform',
    resPass: 'Reset Password',
    resPassSub: 'Reset your account password using your code',
    resPassSub2: 'Tell us your email so we can send you a reset link',
    checkEmail: 'Reset Password',
    checkEmailSub: 'Confirm registration using your code',
    resCode: 'Don’t have a code? Resend code',
  },
  schoolEngagement: {
    cardPlaceholder: 'No activity',
    newSchool: 'New School',
    phoneContact: 'Phone contacts',
    emailContact: 'Email contacts',
    gameModalTitle: 'Default game checklist',
    gameModalSubtitle: 'This list will be populated to every school card moved to "organising" column',
    addItem: 'Add item',
  },
  games: {
    games: 'Games',
    gamesList: 'Games List',
    showMore: 'Show more',
    name: 'Name',
    status: 'Status',
    type: 'Type',
    starts: 'Starts',
    ends: 'Ends',
    dates: 'Game dates',
    startsAt: 'Game start date',
    endsAt: 'Game end date',
    anticipationStartsAt: 'Anticipation start date',
    sustainEndsAt: 'Sustain end date',
    teamsStartsAt: 'Teams start date',
    players: 'Players',
    miles: 'Miles',
    actions: 'Actions',
    state: {
      live: 'Live',
      finished: 'Finished',
      anticipation: 'Anticipation',
      sustain: 'Sustain',
      draft: 'Draft',
    },
    add: 'Add game',
    edit: 'General settings',
    remove: 'Remove game',
    searchGames: 'Search games',
    gameSurveys: 'Game surveys',
    areYouSureToRemoveGame: 'Are you sure to remove this game? This action is irreversible.',
    helpWidget: 'Help Widget',
    standardWidget: 'Standard',
    standardAndMobileWidget: 'Standard + Mobile',
    standardAndWelshWidget: 'Standard + Welsh',
    findCoordinates: 'Find coordinates',
    datesValidation: {
      before: 'should be before',
      after: 'should be after',
    },
  },
  surveys: {
    reminderEmails: {
      title: 'Reminder Emails',
      text: 'Schedule reminder emails in last week of game.',
      note: 'NOTE: Reminder emails will also be sent 1, 7 and 13 days after the game ends.',
      finalDay: 'Final day',
      oneDay: '1 day before',
      twoDays: '2 days before',
      threeDays: '3 days before',
      fourDays: '4 days before',
      fiveDays: '5 days before',
      sixDays: '6 days before',
      sevenDays: '7 days before',
    },
    sendSwitch: 'Send emails automatically',
  },
  teams: {
    teams: 'Teams',
    addTeam: 'Add Team',
    addNewTeam: 'Add New Team',
    teamName: 'Team name',
    teamLeader: 'Team leader',
    teamLeaderEmail: 'Team leader email',
    pending: 'Pending',
    pendingApproval: 'Pending Approval',
    approved: 'Approved',
    leaderboardType: 'Leaderboard type',
    name: 'Name',
    type: 'Type',
    cardsAllocated: 'Cards allocated',
    rollNumber: 'Roll number',
    members: 'Members',
    points: 'Points',
    rangeTeam: 'Range Team',
    actions: 'Actions',
    editTeam: 'Edit Team',
    teamType: 'Team Type',
    searchTeams: 'Search Teams',
    tagRangeFrom: 'Tag range from',
    tagRangeTo: 'Tag range to',
    tagRanges: 'Tag ranges',
    rangeUse: 'Range use',
    deleteTeam: 'Delete Team',
    deleteTeams: 'Delete Teams',
    deleteTeamsWarning: 'Please be aware that once deleted the Team cannot be restored',
    status: 'Status',
    rejected: 'Rejected',
    teamRejectionReason: 'Team Rejection Reason',
    reason: 'Reason',
    chooseAFile: 'Choose a file',
    empty: 'Empty',
    noTeamLeader: 'No team leader',
    noDescription: 'Description not provided',
    teamInfo: 'Team info',
    description: 'Description',
    rejectionReason: 'Rejection reason',
    leaderboardName: 'Leaderboard name',
    teamLeaders: 'Team leaders',
    validateEmails: 'Validate emails',
    teamStats: 'Team stats',
    totalMembers: 'Total members',
    allPoints: 'All points',
    gems: 'Gems',
    extraPoints: 'Extra points',
    noLeaderboard: 'No leaderboard',
    emptyValueNotAllowed: 'Cannot add an empty value',
    adult: 'Adult',
    child: 'Child',
    appUser: 'App user',
    webUser: 'Web user',
    nameTaken: 'Team name is already taken',
  },
  cards: {
    rangeFrom: 'Range from',
    rangeTo: 'Range to',
    cards: 'Cards',
    addTag: 'Add tag',
    editTag: 'Edit tag',
    addSubrange: 'Add subrange',
    editSubrange: 'Edit subrange',
    deleteSubrange: 'Delete subrange',
    deleteSubranges: 'Delete subranges',
    startRange: 'Start range',
    endRange: 'End range',
    amount: 'Amount',
    name: 'Team name',
    connect: 'Connected to',
    approved: 'Approved',
    unlocked: 'Unlocked',
    locked: 'Locked',
    tagType: 'Tag Type',
    type: 'Subrange Type',
    description: 'Description',
    assignRange: 'Assign Range',
    lockRanges: 'Lock all ranges',
    unlockRanges: 'Unlock all ranges',
    exportToCSV: 'Export to CSV',
    approveAndConnect: 'Approve & Connect',
    team: 'Team',
    distributionPoint: 'Distribution point',
    deleteAll: 'Delete All',
    deleteTag: 'Delete Tag',
    deleteTagWarning: 'Please be aware that once deleted the Tag cannot be restored',
    deleteSubrangeWarning: 'Please be aware that once deleted the subrange cannot be restored',
    rangeIsNotAvailable: 'This card range is already taken in another active game!',
    gameRanges: {
      title: 'Use game ranges',
      subtitle: 'Enable to set up game ranges when automatically assigning card ranges to teams',
    },
    tooltips: {
      delete: 'Delete all selected, UNLOCKED ranges',
      lock: 'It locks all selected ranges, so they can not be changed',
      unlock: 'It unlocks all selected ranges and they can be overwritten',
      approve: 'Approves and assign all selected, LOCKED subranges to teams',
    },
    stepper: {
      lock: 'Lock',
      assign: 'Assign',
      approve: 'Approve',
      unlock: 'Unlock',
      assignButton: 'Assign card ranges',
      assignDescription:
        'After defining the game range and teams, use this step to automatically assign card ranges to each team.',
      lockButton: 'Lock card ranges',
      unlockButton: 'Unlock card ranges',
      lockDescription: 'Locking ranges prevents them from being reassigned while locked.',
      approveButton: 'Approve card ranges',
      approveDescription: 'Approval finalises the range assignment and cannot be undone.',
      completed: "All steps completed - you're finished",
      newRanges: 'New card ranges',
      next: 'Next',
      back: 'Back to ',
    },
  },
  players: {
    players: 'Players',
    player: 'Player',
    addPlayer: 'Add Player',
    addNewPlayer: 'Add New Player',
    playerEdit: 'Player Edit',
    playerName: 'Player Name',
    playerLastName: 'Player Last Name',
    playerDisplayName: 'Player Display Name',
    teamId: 'Team Id',
    selectTeam: 'Select team from list below',
    cancel: 'Cancel',
    addTeam: 'Add Team',
    leaveTeam: 'Leave Team',
    save: 'Save',
    displayName: 'Display name',
    age: 'Age',
    using: 'Using',
    actions: 'Actions',
    searchPlayers: 'Search Players',
    searchSwipes: 'Search Swipes',
    editPlayer: 'Edit Player',
    details: 'Details',
    general: 'General',
    activity: 'Activity',
    surveys: 'Surveys',
    achievements: 'Achievements',
    deleteAccount: 'Delete Account',
    accountDeleteWarning: 'Please be aware that once deleted the account cannot be restored',
    exportData: 'Export Data',
    removeFromActiveGame: 'Remove from Active Game',
    removeFromActiveGameWarning: 'Are you sure you want to remove this Player from {{gameName}} game?',
    otherActions: 'Other actions',
    generateTestSwipes: 'Generate test swipes',
    activeGame: 'Active Game',
    sibTemplateID: 'SendInBlue template ID',
    sendEmails: 'Send Emails',
    name: 'Name',
    team: 'Team',
    type: 'Type',
    totalPoints: 'Total points',
    gemPoints: 'Gem Points',
    awardedPoints: 'Awarded points',
    extraPoints: 'Extra points',
    totalSwipes: 'Total swipes',
    lastActivity: 'Last activity',
    awardPoints: 'Award Points',
    changeTeam: 'Change Team',
    playerInfo: 'Player Info',
    tagType: 'Tag Type',
    tagNumber: 'Tag number',
    cardNumber: 'Card number',
    appNumber: 'Mobile ID',
    resetSendPassword: 'Reset & Send Password',
    editAccount: 'Edit Account',
    loginAsPlayer: 'Login as Player',
    replaceLostTag: 'Replace a Lost Card',
    points: 'Points',
    time: 'Time',
    status: 'Status',
    game: 'Game',
    beatBoxId: 'Beat Box ID',
    from: 'From',
    searchActivity: 'Search Activity',
    emailVerified: 'Email verified',
    birthDate: 'Birth date',
    gems: 'Gems',
    rawSwipes: 'Raw swipes',
    gemType: 'Gem type',
    locationName: 'Location name',
    locationId: 'Location ID',
    addPoints: 'Add points',
    tableFiltersTooltip: 'Table list filters',
    updatePlayer: 'Update Player',
    familyMembers: 'Family Members',
    parentDetails: 'Parent Details',
    linkToParent: 'Link to Parent',
    searchToSeeResult: 'Search players to see the result',
    playerWithIdNotFound: 'Player with id: {{playerId}} not found',
    playerWithEmailNotFound: 'Player with email: {{email}} not found',
    playerWithTagNotFound: 'Player with tag number: {{tagNumber}} not found',
    playerWithPublicNameNotFound: 'Player with Public Name: {{publicName}} not found',
    chooseAttribute: 'Choose attribute',
    searching: 'Searching...',
    noGame: 'No game',
    wtu: {
      latitude: ' WTU Latitude',
      longitude: 'WTU Longitude',
    },
    tags: {
      inactivate: {
        title: 'Manage player card',
        description1: 'Turning card to inactive will result in marking all new swipes as INVALID.',
        description2:
          'This section helps to manage exact dates when card was inactive. This can be used to manually invalidate certain dates or remove all points from cheater account (single card). Dates should be set in game timezone.',
        description3: 'Card is marked as lost already, see advanced setting to granular card management.',
        startDateHelper:
          'Start date of card being inactive. If in past, will invalidate past journeys and remove points.',
        endDateHelper: 'This is optional value. When left empty, card will be inactive for future swipes.',
        advanceHelper: 'Manage exact dates when card was inactive',
        moveDescription:
          'Moving card transfer current player journey to the selected one and recalculate both players point for existing game.',
        playerHelper: 'Target player email',
        tagNumberHelper: "Retype card number you'd like to move",
        markAsLost: 'Mark as lost',
        lost: 'Lost',
      },
      step1: 'Step 1',
      invalidatePrevious: 'Invalidate current player journey',
      step2: 'Step 2',
      moveTag: 'Move Card',
      startDate: 'Start Date',
      endDate: 'End Date',
      onlyNumbers: 'Card number can only consist of numbers',
      tagWithNumberNotFound: 'Card with number {{tagNumber}} not found',
    },
    survey: {
      completeDate: 'Complete Date',
      confirmed: 'Confirmed',
      active: 'Active',
      bame: 'BAME',
      disability: 'Disability',
      ltmc: 'Long term condition',
    },
    searchAttribute: {
      email: 'Email',
      playerId: 'Player ID',
      publicName: 'Display name',
      tagNumber: 'Tag number',
    },
  },
  cardOrders: {
    cardOrders: 'Card Orders',
    games: 'Games',
    type: 'Type',
    supplier: 'Supplier',
    quantity: 'Quantity',
    additionalQuantity: 'Additional Quantity',
    status: 'Status',
    actions: 'Actions',
    newOrder: 'New Order',
    sortBy: 'Sort By',
    year: 'Year',
    orderName: 'Order Name',
    orderInfo: 'Order Info',
    tagRanges: 'Tag Ranges',
    range: 'Range',
    start: 'Start',
    end: 'End',
    rangeStart: 'Range start',
    rangeEnd: 'Range end',
    deliveryDate: 'Delivery date',
    draftOrder: 'Draft Order',
    deleteOrder: 'Delete Order',
    designs: 'Designs',
  },
  opportunities: {
    opportunities: 'Opportunities',
    newOpportunity: 'New Opportunity',
    createNewOpportunity: 'Create a new opportunity',
    location: 'Location',
    confidence: 'Confidence',
    type: 'Programmer type',
    population: 'Population',
    owner: 'Owner',
    status: 'Status',
    actions: 'Actions',
    searchOpportunities: 'Search opportunities',
    year: 'Year',
    createOpportunity: 'Create opportunity',
    opportunity: 'Opportunity',
    proposalsTab: 'Proposals',
    stakeholdersTab: 'Stakeholders',
    proposals: {
      newProposal: 'New Proposal',
      title: 'Title',
      description: 'Description',
      budget: 'Budget',
      population: 'Population',
      status: 'Status',
      type: 'Type',
      editDetails: 'Edit Details',
      proposal: 'Proposal',
      editProposal: 'Edit proposal',
      saveChanges: 'Save changes',
    },
    stakeholders: {
      createNewStakeholder: 'New stakeholder',
      name: 'Name',
      surname: 'Surname',
      role: 'Role',
      email: 'Email',
      linkedIn: 'LinkedIn',
      organization: 'Organization',
      phoneNumber: 'Phone Number',
      editDetails: 'Edit Details',
      stakeholder: 'Stakeholder',
      editStakeholder: 'Edit stakeholder',
      saveChanges: 'Save changes',
    },
  },
  timeline: {
    timeline: 'Game timeline',
    install: 'Install',
    decom: 'Decom',
    quarters: 'Quarters',
    months: 'Months',
  },
  locations: {
    errors: {
      number: 'Must be a number',
      lat: 'Number in range between -90 and 90',
      lng: 'Number in range between -180 and 180',
    },
    friendlyName: 'Friendly Name',
    map: 'Map',
    type: 'Type',
    gemType: 'Gem type',
    beatBoxNumber: 'Beat Box Number',
    searchBeatBoxes: 'Search Beat Boxes',
    searchSchools: 'Search Schools',
    searchBonusBB: 'Search Bonus Beat Boxes',
    searchDistributionPoints: 'Search Distribution Points',
    searchGems: 'Search Gems',
    searchPoi: 'Search POI',
    status: 'Status',
    category: 'Category',
    reported: 'Reported',
    description: 'Description',
    batteryLevel: 'Battery level',
    points: 'Points',
    photo: 'Photo',
    accessible: 'Accessible',
    what3words: 'what3words',
    deviceID: 'Device ID',
    slug: 'Name',
    name: 'Friendly Name',
    longitude: 'Longitude',
    latitude: 'Latitude',
    createNewLocation: 'Create new location',
    loadCoords: 'Load coords',
    generateSlugs: 'Generate Slugs',
    publishControl: 'Publish Map',
    boundaryBox: 'Boundary Box',
    owner: 'Owner',
    mapSettings: 'Map settings',
    import: {
      button: 'Import locations',
      preventModal: {
        header: 'Fill in the missing values',
        content: 'Importing a file is only possible when location distance and boundary box are set.',
      },
      error: 'Could not import locations. Check file format.',
      summaryDialog: {
        title: 'Import locations',
        locations: 'locations',
        errors: 'errors',
        emptyLayers: 'Empty layers: ',
        wrongLayers: 'Unknown layers that will be ignored: ',
        tooltipDisabled: 'Edit all incorrect locations to import',
        tooltipEnabled: 'Proceed with the import',
        importButton: 'Import',
      },
      dataGrid: {
        name: 'Name',
        lat: 'Latitude',
        lng: 'Longitude',
        description: 'Description',
        accessible: 'Accessible',
        status: 'Status',
        actions: 'Actions',
        number: 'Number',
        gemType: 'Gem type',
        statusOptions: {
          error: 'error',
          create: 'create',
          update: 'update',
        },
        headers: {
          beatBox: 'Beat Boxes',
          gem: 'Gems',
          school: 'Schools',
          distributionPoint: 'Distribution Points',
          POI: 'POI',
        },
      },
      sumUpDialog: {
        success: 'Success! All locations was created/updated',
        fail: 'locations failed to create/update',
        reason: 'Reasons:',
      },
    },
  },
  tagRange: {
    from: 'From',
    to: 'To',
    addNew: 'Add new Tag Ranges',
    searchTagRanges: 'Search',
    type: 'type',
    addGameRange: 'Add game range',
    checkRange: 'Check range',
    confirmRange: ' Confirm range',
  },
  events: {
    events: 'Events',
    event: 'Event',
    addEvent: 'Add event',
    searchSchedules: 'Search Schedules',
    internalTitle: 'Internal Title',
    visible: 'Visible for players',
    visibleHelper: 'Note: When visible, event content must be provided in Sanity',
    datesTitle: 'Date and Time',
    pointsTitle: 'Bonus Points',
    status: 'Status',
    points: 'Points',
    schedule: 'Schedule',
    timing: 'Timing',
    locations: 'Locations',
    addNewEvent: 'Add a new event',
    groups: 'Beat Box groups',
    customLocations: 'Custom locations',
    noCustomLocations: 'No custom locations',
    beatBoxes: 'Beat Boxes',
    bonus: 'Bonus Beat Boxes',
    startDate: 'Start date',
    endDate: 'End date',
    startTime: 'Start time',
    endTime: 'End time',
    weekDay: 'Week days',
    allDay: 'All day',
    addTime: 'Add time',
    activeOn: 'Active on',
    pointsMustBeGreaterThanZero: 'Points must be greater than zero',
    atLeastOneDayRequired: 'At least one day is required',
    everyDay: 'Every day',
    endDateMustBeLaterThanStartDate: 'End date must be later than start date',
    endTimeMustBeLaterThanStartDate: 'End time must be later than start time',
    editSchedule: 'Edit Schedule',
    details: 'Details',
    info: 'Event Info',
    deleteSchedule: 'Delete Event',
    deleteSchedules: 'Delete Events',
    deleteScheduleWarning: 'Please be aware that once deleted the Event cannot be restored',
    description: 'Description',
    allBeatBoxes: 'All Beat Boxes',
    custom: 'Groups and Locations',
    createAnEvent: 'Create an Event',
    duplicateEvent: 'Duplicate Event',
    duplicationWarning:
      "Creates duplicate event with the same settings as the selected one. New event will contain '(copy)' in the title and will not be visible for players. Event date will be set as one week from today.",
  },
  eventPoint: {
    eventPointDetails: 'Event Point Details',
    searchPointsByLocation: 'Search Points by Location',
    description: 'Description',
    points: 'Points',
    date: 'Date',
    timing: 'Timing',
    locations: 'Locations',
    category: 'Category',
    deletePoint: 'Detele Event Point',
    deletePoints: 'Delete Event Points',
    deletePointWarning: 'Please be aware that once deleted the Event Point cannot be restored',
  },
  mapping: {
    mapping: 'Mapping',
  },
  leaderboards: {
    leaderboards: 'Leaderboards',
    teams: 'Teams',
    addLeaderboard: 'Add leaderboard',
    editLeaderboard: 'Edit leaderboard',
    searchLeaderboard: 'Search leaderboard',
    name: 'Name',
    teamTypes: 'Team Types',
    customTeams: 'Custom teams',
    numberOfTeams: 'Number of teams',
    actions: 'Actions',
    deleteLeaderboard: 'Delete Leaderboard',
    deleteLeaderboards: 'Delete Leaderboards',
    deleteLeaderboardsWarning: 'Please be aware that once deleted the Leaderboard cannot be restored',
    moveSmallTeams: {
      title: 'Move small teams',
      text: `Move teams with members up to the specified size to a 'Small Teams' leaderboard. This only applies to Community, Running, Cycling and Workplace teams.`,
      label: 'Small team size',
    },
  },
  category: {
    deleteCategory: 'Delete Category',
    deleteCategoryWarning:
      'Please be aware that once deleted the Category cannot be restored. Remember to remove all Points Schedule Events connected to this category',
  },
  locationCategory: {
    deleteLocationCategory: 'Remove location from category',
    deleteLocationCategoryWarning:
      'Are you sure you want to remove this location from category? Remember to remove all Points Schedule records connected to this location ',
  },
  validateSurvey: {
    buttonText: 'Validate Survey',
    surveyDidNotPassValidation: 'The survey did not pass validation',
    surveyIsConstructedProperly: 'The survey is constructed properly',
    questionsOrAnswersAreWrong: 'Some of the questions or answers are either missing or wrongly constructed.',
    allQuestionsAndAnswersPassedValidation:
      'All of the questions and answers for this survey have passed the validation process.',
    close: 'Close',
    failed: 'Failed',
    success: 'Success',
    adultQuestionsLine: 'Adult questions',
    childrenQuestionsLine: 'Children questions',
    adultAnswersLine: 'Adult answers',
    childrenAnswersLine: 'Children answers',
    extraQuestionsLine: 'Extra questions',
    adultAnswers: 'Some of the adult answers are incorrect or missing.',
    adultQuestions: 'Some of the adult questions are incorrect or missing.',
    childrenAnswers: 'Some of the children answers are incorrect or missing.',
    childrenQuestions: 'Some of the children questions are incorrect or missing.',
    extraQuestions: 'Some of the extra questions are incorrect or missing.',
    activityQuestionsMissing: 'Question for activity is missing',
    yesOrNoAnswerMissing: 'There should be at least "Yes" and "Prefer not to say" answer',
    notAllIncludeDays: 'The options should be all days of the week',
    notAllIncludeNumberOfDays: 'All of the options should include number of days',
    doNotContainHourOrNumber: 'All of the options should have an hour or number of minutes included',
    noDisabilityQuestion: 'Question about disability is missing',
    noMedicalConditionQuestion: 'Question about medical condition is missing',
    noEthnicityQuestions: 'Question about ethnicity is missing',
    validEthnicityAnswersMissing:
      'Ethnicity answers should include options for BAME, white ethnic and "Prefer not to say"',
    snackbarError: 'An error occurred while validating survey.',
  },
  exportPlayerData: {
    notPlayersForThisGame: 'There are not any players for this game yet.',
    playerEmail: 'Player email',
    gameName: 'Game name',
    gameConsenting: 'Game consenting',
    gameConsentText: 'Game consent text',
    gameConsentTime: 'Game consent time',
    couldNotFindPlayers: 'Could not find any players for selected option',
    exportPlayers: 'Export players',
    exportPlayersDataTitle: 'Export players data',
    selectExportOption: 'Select export option',
    emailReceivers: 'Email receivers',
    appPlayersTooltip: 'Players that are 13 years old or above.',
    appPlayers: 'App players',
    playersConsentedTooltip: 'Players who have consented sharing with the Game partner.',
    allPlayers: 'All players',
    allPlayersTooltip: 'All players in this game.',
    zipPasswordLabel: 'Add password for Zip',
    closeModalButton: 'Close',
    exportModalButton: 'Export',
    toggleButtonExportPlayers: 'Export players',
    toggleButtonSendEmails: 'Send Emails',
    exportError: 'No players matched for selected option',
    exportSuccess: 'Players exported successfully!',
  },
  listTableFilters: {
    titleText: 'Players Filters',
    ageTitle: 'Player age',
    ageFrom: 'Age from',
    ageTo: 'Age to',
    resetButton: 'Reset',
  },
  stats: {
    stats: 'Stats',
    welcome: 'Welcome',
    registered: 'Registered players',
    active: 'Active players',
    players: 'Players',
    numberOfPlayers: 'Number of players',
    tooltipRegistered: 'Number of players with accounts registered by web or mobile',
    seeAllRegistered: 'View registered players',
    allPlayers: 'Total players',
    tooltipAllPlayers: 'Number of all player in game, registered and playing only by card',
    seeAll: 'View all players',
    seePlayers: 'See player data',
    seeWeather: 'View weather',
    seeEvents: 'See game events',
    seeDemographic: 'See demographic data',
    seeBeatBox: 'See Beat Box data',
    seeActivity: 'See activity data',
    seeLocations: 'See locations',
    gameStatus: 'Game status',
    participationTarget: 'Participation target',
    numberOfTeams: 'Teams playing',
    milesCovered: 'Game mileage',
    milesPerPlayer: 'Average of {{number}} per player',
    targetPercent: ' of target',
    populationPercent: '% of population reached',
    swipes: 'Beat Box swipes',
    averageSwipes: 'Average of {{number}} per player ',
    visited: 'Beat Boxes visited today',
    partnerConsents: 'Partner communication consent',
    newsletterConsents: 'BTS newsletter consent',
    percentOfAdults: ' of registered adults',
    surveysCompleted: 'Health surveys completed',
    percentOfTotal: ' of players',
    percentOfRegistered: ' of registered players',
    anonymisedPlayers: 'Anonymised players',
    reviews: 'reviews',
    overallReviews: 'Overall Reviews',
    totalReviews: 'reviews in total',
    yearsAgo: 'years ago',
    monthsAgo: 'months ago',
    daysAgo: 'days ago',
    hoursAgo: 'hours ago',
    minutesAgo: 'minutes ago',
    secondsAgo: 'seconds ago',
    someTimeAgo: 'some time ago',
    daysOff: 'Day {{gameDays}} of {{gameTime}}',
    daysOn: 'Day {{gameDays}} of week {{gameWeek}}',
    gameIn: 'Game in',
    day: 'Day',
    gameDay: 'Game day',
    ofGameComplete: 'of game complete',
    highestSwipedBeatBox: 'Highest swiped Beat Box',
    averageSwipesPerBeatBox: 'Average of {{number}} each',
    highestFrequencyDay: 'Highest frequency day',
    lowestFrequencyDay: 'is lowest',
    highestFrequencyTime: 'Highest frequency time',
    lowestFrequencyTime: 'is lowest',
    swipesByTimeOfDay: 'Swipes by time of day',
    totalCards: 'Total cards in game',
    percentOfDistribution: 'of cards used',
    events: {
      title: 'Upcoming game events',
      name: 'Name',
      points: 'Points',
      date: 'Date',
      status: 'Status',
      noEvents: 'No upcoming events',
    },
    demographic: {
      title: 'Headline demographic data',
      metric: 'Metric',
      playersTotal: 'Total players',
      playersPercent: '% of Total players',
      children: 'Number of children',
      female: 'Number of female adults',
      inactiveAdult: 'Number of adults who are inactive',
      lessActiveChild: 'Number of children who are less active',
      imd: 'Number from IMD groups 1-4',
      bame: 'Number who report a non-white ethnicity',
      condition: 'Number with long-term medical condition',
      disability: 'Number with a disability',
    },
    tooltips: {
      gameProgress: 'Current game day and game week',
      weather: 'Weather forecast in game location',
      beatBoxesVisited: 'Number of Beat Boxes visited today',
      players:
        'Number of players, with distribution between adults, children and anonymous profiles. Anonymous profiles are players who have not registered.',
      totalPlayers: 'Number of people who have either registered or tapped',
      registeredPlayers: 'Number of players who have setup an account',
      beatBoxSwipes: 'Total number of unique taps recorded',
      mileage: 'Calculated based on a distance of {{distanceSetting}} miles between Beat Boxes',
      partnerConsents: 'Number of players who have consented to share their data with the game partner',
      newsletterConsents: 'Number of people signed up to game newsletter',
      surveysCompleted: 'Total number of health surveys completed by players',
      demographic: 'Headline demographic data taken from game profiles and survey responses',
      events: 'Upcoming events in the area',
      swipesByTimeOfDay: 'Number of swipes made at time intervals throughout the day',
      locationFrequency: 'Number of taps recorded at each Beat Box',
      totalCards: 'Total number of cards which have been used during the game',
      frequencyDay: 'Days on which most or least taps have been made',
      frequencyTime: 'Times at which most or least taps have been made, to the nearest hour',
      highestSwipedBeatBox: 'Beat Box with the most swipes',
      allRegisteredPlayersPercent: '% of registered players',
      allSurveyResponsesPercent: '% of survey respondents',
      allRegisteredAdultPlayersPercent: '% of registered adult players',
      surveyResponsesAdultPercent: '% of adult survey respondents',
      surveyResponsesChildPercent: '% of child survey respondents',
      anonymised: "Number of players who's accounts have been anonymised",
    },
    locationFrequency: {
      title: 'Location frequency',
      description: 'Description',
      id: 'ID',
      visits: 'Visits',
      noLocations: 'No game locations',
    },
    swipesChart: {
      visited0003: '0-3',
      visited0306: '3-6',
      visited0609: '6-9',
      visited0912: '9-12',
      visited1215: '12-15',
      visited1518: '15-18',
      visited1820: '18-20',
      visited2100: '21-00',
    },
    hours: {
      h0: '12am',
      h1: '1am',
      h2: '2am',
      h3: '3am',
      h4: '4am',
      h5: '5am',
      h6: '6am',
      h7: '7am',
      h8: '8am',
      h9: '9am',
      h10: '10am',
      h11: '11am',
      h12: '12pm',
      h13: '1pm',
      h14: '2pm',
      h15: '3pm',
      h16: '4pm',
      h17: '7pm',
      h18: '6pm',
      h19: '7pm',
      h20: '8pm',
      h21: '9pm',
      h22: '10pm',
      h23: '11pm',
    },
    days: {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday',
    },
  },
  demographic: {
    widgetTitles: {
      registered: 'Registered players',
      responses: 'Survey responses',
      bame: 'Number who report a non-white ethnicity',
      disability: 'Number with a disability',
      condition: 'Number with long term medical condition',
    },
    filters: {
      filters: 'Filters',
      reset: 'Reset all filters',
      age: 'Age',
      ageGap1: '0-11',
      ageGap2: '12-18',
      ageGap3: '19-55',
      ageGap4: '56+',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      activityAdult: 'Adults activity level',
      activityChild: 'Children activity level',
      active: 'Active',
      fairly: 'Fairly',
      inactive: 'Inactive',
      lessActive: 'Less active',
      teamType: 'Team type',
      school: 'School',
      workplace: 'Workplace',
      community: 'Community',
      running: 'Running',
      cycling: 'Cycling',
      charity: 'Charity',
      SENSchool: 'Sen School',
      noTeam: 'No team',
      imd: 'IMD Decile',
    },
    chipTooltips: {
      registered: 'of registered players',
      responses: 'of survey responses',
      segmented: 'of segmented group',
    },
    tooltips: {
      registered: 'Number of registered players',
      responses: 'Number of survey responses',
      bame: 'Number of registered players who report a non-white ethnicity',
      disability: 'Number of registered players who report a disability',
      condition: 'Number of registered players who report a long-term medical condition',
    },
    imdTable: {
      decile: 'IMD decile',
      numberOfPlayers: 'Number of players',
      percentOfPlayers: '% of total players',
      population: 'Population',
      percentOfPopulation: '% of total population',
      net: 'Net representation',
    },
  },
  analyticsTeams: {
    teams: 'Teams',
    chart: {
      tooltip: 'Number of teams with breakdown by team type',
      numberOfTeams: 'Number of teams',
    },
    teamTable: {
      title: 'Team List',
      name: 'Team name',
      type: 'Team type',
      numberOfPlayers: 'Team players',
      percentOfPlayers: '% of total team players',
      points: 'Total points',
      perPlayer: 'Points per player',
      noData: 'No available data based on applied filters',
      tooltip: 'Participation and activity data for teams, excluding schools',
    },
    schoolTable: {
      title: 'School team list',
      name: 'School name',
      pupils: 'Number of pupils',
      numberOfPlayers: 'School players',
      percentOfPlayers: '% of total school players',
      reach: '% Reach',
      points: 'Total points',
      perPlayer: 'Points per player',
      noData: 'No available data based on applied filters',
      tooltip: 'Participation and activity data for school teams',
    },
  },
  mappings: {
    popup: {
      lsoa: 'LSOA Code',
      name: 'LSOA Name',
      swipes: 'Swipes',
      swipesNum_one: '{{count}} swipe',
      swipesNum_other: '{{count}} swipes',
      uniquePlayers: 'Unique players',
      uniquePlayersNum_one: '{{count}} player',
      uniquePlayersNum_other: '{{count}} players',
      reportBeatbox: 'Report Beat Box',
      inactivityPercentageAdult: 'Inactive adults',
      inactivityPercentageChild: 'Less active children',
      inactivityPercentageNum: '{{count}}%',
      totalSurveysAdult: 'Adult surveys completed',
      totalSurveysChild: 'Child surveys completed',
      totalSurveysNum_one: '{{count}} survey',
      totalSurveysNum_other: '{{count}} surveys',
      idxPercentage: 'IDX Percentage',
      idxPercentageNum: '{{count}}%',
    },
    tabs: {
      layersHeader: 'Data overlays',
      layersLiveData: 'Live data',
      layersOpenData: 'Open data',
      layers: {
        geographicData: 'Geographic data',
        playersData: 'Player data',
        locations: 'Locations',
        activityLevelsByLsoaAdult: {
          title: 'Adult inactivity levels',
          tooltipTitle: 'Adult inactivity levels by LSOA',
          tooltipContent:
            'Map overlay by LSOA of game adult participants indicating the inactivity levels of the population on a percentage scale.',
        },
        activityLevelsByLsoaChild: {
          title: 'Child inactivity levels',
          tooltipTitle: 'Child inactivity levels by LSOA',
          tooltipContent:
            'Map overlay by LSOA of game child participants indicating the inactivity levels of the population on a percentage scale.',
        },
        indexOfPenetrationByLsoa: {
          title: 'Player participation',
          tooltipTitle: 'Participation by LSOA',
          tooltipContent:
            'Map overlay by LSOA of game participants indicating the participation levels of the population on a percentage scale.',
        },
        swipeActivityByLocation: {
          title: 'Swipe activity',
          tooltipTitle: 'Swipe activity by location',
          tooltipContent:
            'Map overlay by LSOA of game participants indicating the activity levels of the population on a percentage scale.',
        },
        registrationByPostcode: {
          title: 'Registration',
          tooltipTitle: 'Registration by postcode',
          tooltipContent:
            'Map overlay by LSOA of game participants indicating the activity levels of the population on a percentage scale.',
        },
      },
      locations: {
        beatboxes: {
          title: 'Beat Boxes',
        },
        gems: {
          title: 'Gems',
        },
        schools: {
          title: 'Schools',
        },
        distributionPoints: {
          title: 'Distribution points',
        },
      },
      filtersHeader: 'Filters',
    },
    settings: {
      title: 'Settings',
      tooltip: 'Customise how data is displayed on the map, including options for thresholds and visual preferences.',
      minSurveysTitle: 'LSOA survey responses',
      minSurveysDescription:
        'Show data for LSOAs only if the survey responses from those areas reach or exceed the minimum threshold.',
      minSurveysInputLabel: 'Minimum survey responses',
      hardCodedDataLabel: 'Display hard codded data (West Reading Game)',
    },
    minSurveyText: 'Minimum surveys in area:',
    minSurveyTooltip: 'Highlights only those LSOAs which have more survey completions than stated number.',
  },
  access: {
    accessDenied:
      'Your account does not have permission to view this {{resourceName}}. Please try using a different account or contact an administrator',
    game: 'game',
    player: 'player',
    resource: 'resource',
    noGames: 'No games configured.',
  },
  messages: {
    newMessage: 'New message',
    name: 'Name',
    channel: 'Channel',
    schedule: 'Schedule',
    status: 'Status',
    search: 'Search Messages',
    deleteMessage: 'Delete Message',
    deleteMessages: 'Delete Messages',
    deleteMessageWarning: 'Please be aware that once deleted the Message cannot be restored',
  },
}

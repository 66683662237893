import { Suspense, lazy } from 'react'
import type { RouteObject } from 'react-router'
import LoadingScreen from './components/LoadingScreen'
import AuthGuard from './components/global/AuthGuard'
import GlobalAccessGuard from './components/global/GlobalAccessGuard'
import GuestGuard from './components/global/GuestGuard'
import Layout from './components/layout/logged/Layout'
import MainLayout from './components/layout/logout/MainLayout'
import { GROUPS } from './constants'
import Activity from './pages/gameRelated/Activity'

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// Dashboard pages
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')))

// Admin pages
const UsersList = Loadable(lazy(() => import('./pages/users')))
const UserEdit = Loadable(lazy(() => import('./pages/users/Edit')))

// // Card List
const PlayersSearch = Loadable(lazy(() => import('./pages/players')))
// const CardListPlayerView = Loadable(lazy(() => import('./pages/adminRelated/Players/Edit')))

//TimelineView
const Timelines = Loadable(lazy(() => import('./pages/timelines')))

// Games pages
const Games = Loadable(lazy(() => import('./pages/gameRelated/List')))
const GameLayout = Loadable(lazy(() => import('./components/layout/game')))
const Stats = Loadable(lazy(() => import('./pages/gameRelated/Stats')))
const GameEdit = Loadable(lazy(() => import('./pages/gameRelated/Settings')))
const Players = Loadable(lazy(() => import('./pages/gameRelated/Players')))
const PlayerDetails = Loadable(lazy(() => import('./pages/gameRelated/Players/components/PlayerDetails')))
const LeaderboardsTypes = Loadable(lazy(() => import('./pages/gameRelated/Leaderboards')))
const LeaderboardTypesEdit = Loadable(lazy(() => import('./pages/gameRelated/Leaderboards/components/Edit')))
const Cards = Loadable(lazy(() => import('./pages/gameRelated/Cards')))
const CardsEdit = Loadable(lazy(() => import('./pages/gameRelated/Cards/TeamRanges/components/EditTag')))
const Demographics = Loadable(lazy(() => import('./pages/gameRelated/Demographics')))
const TeamsAnalytics = Loadable(lazy(() => import('./pages/gameRelated/TeamsAnalytics')))
const MappingAnalytics = Loadable(lazy(() => import('./pages/gameRelated/MappingAnalytics')))
const Surveys = Loadable(lazy(() => import('./pages/gameRelated/Surveys')))

// Team pages
const TeamsList = Loadable(lazy(() => import('./pages/gameRelated/Teams')))
const TeamDetails = Loadable(lazy(() => import('./pages/gameRelated/Teams/components/TeamDetails')))

const Location = Loadable(lazy(() => import('./pages/gameRelated/Location')))
const Mapping = Loadable(lazy(() => import('./pages/gameRelated/Mapping')))
const TagRangeEdit = Loadable(lazy(() => import('./pages/gameRelated/Cards/GameRanges/components/EditTags')))
//
// CardOrders pages
const CardOrders = Loadable(lazy(() => import('./pages/cardOrders')))
const CardOrderEdit = Loadable(lazy(() => import('./pages/cardOrders/components/Edit')))
//
// //EventsSchedule
const EventsScheduleDetails = Loadable(lazy(() => import('./pages/gameRelated/Events/components/Details')))
const EventsScheduleCreate = Loadable(lazy(() => import('./pages/gameRelated/Events/components/Create')))
const EventSchedule = Loadable(lazy(() => import('./pages/gameRelated/Events')))
// Auth pages
const Login = Loadable(lazy(() => import('./pages/auth/Login')))
// const NewPasswordChallenge = Loadable(lazy(() => import('./pages/auth/NewPasswordChallenge')))
// const PasswordRecovery = Loadable(lazy(() => import('./pages/auth/PasswordRecovery')))
// const PasswordReset = Loadable(lazy(() => import('./pages/auth/PasswordReset')))
// const Register = Loadable(lazy(() => import('./pages/auth/Register')))
// const VerifyCode = Loadable(lazy(() => import('./pages/auth/VerifyCode')))

// Engagements pages
const Messages = Loadable(lazy(() => import('./pages/gameRelated/Engagement/Messages')))
const Schedule = Loadable(lazy(() => import('./pages/gameRelated/Engagement/Schedule')))
const Segments = Loadable(lazy(() => import('./pages/gameRelated/Engagement/Segments')))
const Delivery = Loadable(lazy(() => import('./pages/gameRelated/Engagement/Delivery')))
const Templates = Loadable(lazy(() => import('./pages/gameRelated/Engagement/Templates')))
const CreateMessage = Loadable(lazy(() => import('./pages/gameRelated/Engagement/Messages/components/Create')))

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/error/AuthorizationRequired')))
const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')))

const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <AuthGuard groups={[GROUPS.admin, GROUPS.partner]}>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/players',
        element: (
          <GlobalAccessGuard>
            <PlayersSearch />
          </GlobalAccessGuard>
        ),
      },
      {
        path: '/timeline',
        element: (
          <GlobalAccessGuard>
            <Timelines />
          </GlobalAccessGuard>
        ),
      },
      {
        path: '/players/details/:playerId',
        element: (
          <GlobalAccessGuard>
            <PlayerDetails />
          </GlobalAccessGuard>
        ),
      },
      {
        path: '/games',
        element: <Games />,
      },
      {
        path: '/games/:slug',
        element: <GameLayout />,
        children: [
          {
            path: '/games/:slug/overview',
            element: <Stats />,
          },
          {
            path: '/games/:slug/general',
            element: <GameEdit />,
          },
          {
            path: '/games/:slug/surveys',
            element: <Surveys />,
          },
          {
            path: '/games/:slug/players',
            element: <Players />,
          },
          {
            path: '/games/:slug/players/details/:playerId',
            element: <PlayerDetails />,
          },
          {
            path: '/games/:slug/leaderboards',
            element: <LeaderboardsTypes />,
          },
          {
            path: '/games/:slug/leaderboards/edit/:leaderboardId',
            element: <LeaderboardTypesEdit />,
          },
          {
            path: '/games/:slug/teams',
            element: <TeamsList />,
          },
          {
            path: '/games/:slug/teams/details/:teamId',
            element: <TeamDetails />,
          },
          {
            path: '/games/:slug/cards/game-ranges',
            element: <Cards />,
          },
          {
            path: '/games/:slug/cards/team-ranges',
            element: <Cards />,
          },
          {
            path: '/games/:slug/cards/edit/:cardsId',
            element: <CardsEdit />,
          },
          {
            path: '/games/:slug/events',
            element: <EventSchedule />,
          },
          {
            path: '/games/:slug/events/create',
            element: <EventsScheduleCreate />,
          },
          {
            path: '/games/:slug/events/details/:eventId',
            element: <EventsScheduleDetails />,
          },
          {
            path: '/games/:slug/location',
            element: <Location />,
          },
          {
            path: '/games/:slug/mapping',
            element: <Mapping />,
          },
          {
            path: '/games/:slug/tagRange/edit/:tagId',
            element: <TagRangeEdit />,
          },
          {
            path: '/games/:slug/demographics',
            element: <Demographics />,
          },
          {
            path: '/games/:slug/analytics-teams',
            element: <TeamsAnalytics />,
          },
          {
            path: '/games/:slug/activity',
            element: <Activity />,
          },
          {
            path: '/games/:slug/mapping-analytics',
            element: <MappingAnalytics />,
          },
          {
            path: '/games/:slug/messages',
            element: <Messages />,
          },
          {
            path: '/games/:slug/messages/create',
            element: <CreateMessage />,
          },
          {
            path: '/games/:slug/segments',
            element: <Segments />,
          },
          {
            path: '/games/:slug/schedule',
            element: <Schedule />,
          },
          {
            path: '/games/:slug/delivery',
            element: <Delivery />,
          },
          {
            path: '/games/:slug/templates',
            element: <Templates />,
          },
        ],
      },
      {
        path: 'admin',
        element: <AuthGuard groups={[GROUPS.admin]} />,
        children: [
          {
            path: '',
            element: <Dashboard />,
          },
          {
            path: '/admin/members',
            element: <UsersList />,
          },
          {
            path: '/admin/members/edit/:id',
            element: <UserEdit />,
          },
          {
            path: '/admin/card-orders',
            element: <CardOrders />,
          },
          {
            path: '/admin/card-orders/edit/:cardOrderId',
            element: <CardOrderEdit />,
          },
        ],
      },
      {
        path: '*',
        element: <Dashboard />,
      },
    ],
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

export default routes

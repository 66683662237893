type ENVType = {
  apiUrl: string
  cognitoPoolId: string
  cognitoClientId: string
  cognitoIdPoolId: string
  env: string
  local: boolean
}

const getEnv = (): ENVType => {
  const isLocal = !!(process.env.NODE_ENV !== 'production' && process.env.REACT_APP_LOCAL == 'true')
  const baseUrl = isLocal ? 'http://localhost:4000' : process.env.REACT_APP_API_URL
  const url = baseUrl + (baseUrl[baseUrl.length - 1] === '/' ? '' : '/') + 'graphql'
  return {
    apiUrl: url,
    cognitoPoolId: process.env.REACT_APP_COGNITO_POOL_ID || '',
    cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
    cognitoIdPoolId: process.env.REACT_APP_ID_POOL_ID || '',
    env: process.env.REACT_APP_ENV || '',
    local: isLocal,
  }
}

const env = getEnv()

export default env
